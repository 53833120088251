<template>
  <div class="AppCart">
    <div class="AppCart container">
      <div class="cart__banner">
        <ul class="carts__items" @click="$router.back()">
          <li class="AppCart__arrow carts__item">
            <icon-arrow class="iconArrow--left iconArrow--light-yellow"/>
          </li>
          <li class="AppCart__back-txt carts__item">{{backText['text_' + selectedLang]}}</li>
        </ul>
        <div class="cart__price">
          Всего:
          <div class="cart__sum">
            {{ getCartTotalPrice }} <span class="cart__currency">{{wallet['text_' + selectedLang]}}</span>
          </div>
        </div>
      </div>
      <div v-show="!getCartItemsTotalAmount && !getCartPiesTotalAmount" class="AppCart__error">
        {{cartNull['text_' + selectedLang]}}
      </div>
      <div v-show="getCartItemsTotalAmount && getCartPiesTotalAmount && !getData.chooseAllBool" class="AppCart__error">
        {{text1['text_' + selectedLang]}}<br><br>
        {{text2['text_' + selectedLang]}}
        <div class="cart__buttonGroup">
          <div class="cart__button">
            <button @click="changeAllBool(true)">
              <app-button class="button--fill">{{yes['text_' + selectedLang]}}</app-button>
            </button>
          </div>
          <div class="cart__button">
            <button @click="changeAllBool(false)">
              <app-button class="button--fill">{{no['text_' + selectedLang]}}</app-button>
            </button>
          </div>
        </div>
      </div>
      <div v-show="getCartItemsTotalAmount">
        <div class="AppCart__list">
          <ul class="AppCart__items cart__items">
            <li
                v-for="(itemInCart, name) in getCartItems"
                :key="+name + +itemInCart.price"
                class="AppCart__item cart__item"
            >
              <div class="cart__name-product">
            <span class="AppCart__amount cart__amount"
            >x{{ itemInCart.amount }}</span
            >
                <span class="AppCart__title cart__title">{{
                    itemInCart['title_' + selectedLang]
                  }}</span>
                <span class="AppCart__price cart__price"
                >{{ itemInCart.price }} {{wallet['text_' + selectedLang]}}</span
                >
              </div>

              <div class="cart__change-amount">
                <div
                    class="cart__minus"
                    @click="
                addItemInCart(
                  name,
                  --itemInCart.amount,
                  itemInCart.title,
                  itemInCart.price
                )
              "
                >
                  <icon-minus/>
                </div>
                <div
                    class="cart__plus"
                    @click="
                addItemInCart(
                  name,
                  ++itemInCart.amount,
                  itemInCart.title,
                  itemInCart.price
                )
              "
                >
                  <icon-plus class="cart__plus"/>
                </div>
              </div>
            </li>
          </ul>
          <form action="" class="AppCart__form cart__form" v-show="!getData.allBool">
            <app-input
                class="cart__input"
                :type="'text'"
                :placeholder="note['text_' + selectedLang]"
                :nameInput="'cartNoteItems'"
            />
            <div class="cart__button">
              <button @click="dropCart()">
                <router-link :to="{ name: 'AppOrdering', params: { type: 'item'} }">
                  <app-button class="button--fill">{{orderingText['text_' + selectedLang]}}</app-button>
                </router-link>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div v-show="getCartPiesTotalAmount">
        <div class="AppCart__list">
          <ul class="AppCart__items cart__items">
            <li
                v-for="(pieInCart, name) in getCartPies"
                :key="+name + +pieInCart.price"
                class="AppCart__item cart__item"
            >
              <div class="cart__name-product">
            <span class="AppCart__amount cart__amount"
            >x{{ pieInCart.amount }}</span
            >
                <span class="AppCart__title cart__title">{{
                    pieInCart['title_' + selectedLang]
                  }}</span>
                <span class="AppCart__price cart__price"
                >{{ pieInCart.price }} {{wallet['text_' + selectedLang]}}</span
                >
              </div>

              <div class="cart__change-amount">
                <div
                    class="cart__minus"
                    @click="
                addPieInCart(
                  name,
                  --pieInCart.amount,
                  pieInCart.title,
                  pieInCart.price
                )
              "
                >
                  <icon-minus/>
                </div>
                <div
                    class="cart__plus"
                    @click="
                addPieInCart(
                  name,
                  ++pieInCart.amount,
                  pieInCart.title,
                  pieInCart.price
                )
              "
                >
                  <icon-plus class="cart__plus"/>
                </div>
              </div>
            </li>
          </ul>

          <form action="" class="AppCart__form cart__form" v-show="!getData.allBool">
            <app-input
                class="cart__input"
                :type="'text'"
                :placeholder="note['text_' + selectedLang]"
                :nameInput="'cartNotePies'"
            />
            <div class="cart__button">
              <router-link :to="{ name: 'AppOrdering', params: { type: 'pie'} }">
                <app-button class="button--fill">{{orderingText['text_' + selectedLang]}}</app-button>
              </router-link>
            </div>
          </form>
          <form action="" class="AppCart__form cart__form" v-show="getData.allBool">
            <app-input
                class="cart__input"
                :type="'text'"
                :placeholder="note['text_' + selectedLang]"
                :nameInput="'cartNotePies'"
            />
            <div class="cart__button">
              <router-link :to="{ name: 'AppOrdering', params: { type: 'all'} }">
                <app-button class="button--fill">{{orderingText['text_' + selectedLang]}}</app-button>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions as mapRootAction, mapGetters as mapRootGetters} from 'vuex';
import AppButton from '../../../components/AppButton';
import iconMinus from '../../../components/svg/iconMinus';
import iconPlus from '../../../components/svg/iconPlus';
import iconArrow from '../../../components/svg/iconArrow';
import AppInput from "../../../components/AppInput.vue";

const {mapActions, mapGetters} = createNamespacedHelpers("ordering");

export default {
  name: '',
  components: {
    AppButton,
    iconMinus,
    iconPlus,
    iconArrow,
    AppInput,
  },
  data() {
    return {
      backText: {
        text_ru: 'Вернуться',
        text_ua: 'Повернутися',
        text_pl: 'Powrót',
        text_en: 'Return',
      },
      wallet: {
        text_ru: 'грн',
        text_ua: 'грн',
        text_pl: 'hrywien',
        text_en: 'UAH',
      },
      text1: {
        text_ru: 'Чтобы испечь лучшый пирог нам нужен хотя бы день',
        text_ua: 'Щоб спекти топовий пиріг нам потрібен хоча б день',
        text_pl: 'Na upieczenie najlepszego ciasta potrzebujemy przynajmniej jednego dnia',
        text_en: 'We need at least a day to bake the best cake',
      },
      text2: {
        text_ru: 'Хотите заказать блюда из меню и пироги на одно время?',
        text_ua: 'Хочете замовити страви з меню і пироги на один час?',
        text_pl: 'Chcesz zamówić jednocześnie dania z karty i ciasta?',
        text_en: 'Do you want to order dishes from the menu and pies at the same time?',
      },
      yes: {
        text_ru: 'Да, конечно',
        text_ua: 'Так, звичайно',
        text_pl: 'Tak, oczywiście',
        text_en: 'Yes of course',
      },
      no: {
        text_ru: 'Нет, спасибо',
        text_ua: 'Ні, дякую',
        text_pl: 'Nie, dziękuję',
        text_en: 'No, thanks',
      },
      note: {
        text_ru: 'Примечание',
        text_ua: 'Примітка',
        text_pl: 'Uwaga',
        text_en: 'Note',
      },
      orderingText: {
        text_ru: 'Заказать домой',
        text_ua: 'Замовити додому',
        text_pl: 'Zamów do domu',
        text_en: 'Order home',
      },
      cartNull: {
        text_ru: 'Сейчас в Вашей корзине ничего нет.',
        text_ua: 'Зараз у Вашій корзині нічого немає.',
        text_pl: 'Twój koszyk jest obecnie pusty.',
        text_en: 'Your cart is currently empty.',
      },
    };
  },

  methods: {
    addItemInCart(id, amount, title, price) {
      this.addCartItem({
        id: id,
        amount: amount,
        title: title,
        price: price,
      });
    },

    addPieInCart(id, amount, title, price) {
      this.addCartPie({
        id: id,
        amount: amount,
        title: title,
        price: price,
      });
    },

    changeAllBool(bool) {
      this.changeData({key: 'allBool', value: bool})
      this.changeData({key: 'chooseAllBool', value: true})
    },

    ...mapRootAction([
      'isDropMenuActive',
      'changeItemsInCart',
      'dropCart',
      'addCartItem',
      'addCartPie',
      'updateItemAmount',
    ]),

    ...mapActions([
      'changeData',
    ]),
  },
  computed: {
    ...mapRootGetters([
      'getCartItemsTotalAmount',
      'getCartPiesTotalAmount',
      'getIsDropMenuActive',
      'header',
      'getCartTotalPrice',
      'getCartIsDropped',
      'getCartItems',
      'getCartPies',
    ]),
    ...mapGetters([
      'getData',
    ]),

    ...mapRootGetters('lang', ['selectedLang']),
  },
};
</script>

<style lang="scss">
.AppCart {
  &__arrow {
    margin-right: 5px;
  }

  &__back-txt {
    @include text($h16, 300, $light-yellow, $f-montserrat);
  }

  &__item {
    border-top: 1px solid $grey;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &__input {
    max-width: 300px;
    margin-top: 45px;
  }

  &__error {
    @include text($h16, 300, $light-yellow, $f-montserrat);
    margin-bottom: 50px;
  }

  &__price {
    @include flex(flex-start, center, row, nowrap);
  }

  &__list {
    width: 100%;
    margin-top: 15px;
  }

  &__buttonGroup {
    display: flex;
    flex-direction: row;

    .button {
      min-width: 100px;
      margin: 10px;
      padding: 0 10px;
    }
  }
}
</style>
